import React from 'react';
import PropTypes from 'prop-types';

import Swiper from '@ecp/common/src/components/swiper/Swiper';
import {
  MdPickCarouselItem,
  MenuCarouselItem,
  SwiperAdBannerItem,
  SwiperBannerItem,
  SwiperBannerItemATag,
  SwiperBannerPopupItem,
  SwiperMainItem,
  SwiperMainExhibitionItem,
} from '@ecp/common/src/components/swiper/component/SwiperItem';

export const SwiperMain = (props) => (
  <Swiper
    arrowProps={{ type: '30', position: 'inner', strokeWidth: '2px' }}
    control="inner"
    itemComponent={SwiperMainItem}
    {...props}
  />
);

SwiperMain.displayName = 'SwiperMain';

SwiperMain.propTypes = {
  itemList: PropTypes.array,
  itemProps: PropTypes.object,
  arrowProps: PropTypes.object,
  onClick: PropTypes.func,
  carouselProps: PropTypes.object,
  width: PropTypes.string,
};

export const SwiperMainDefaultProps = {
  carouselProps: {
    delay: 4000,
    slidesPerView: 1,
    spaceBetween: 0,
    autoplay: true,
    loop: true,
  },
  itemProps: {
    width: '1340px',
    height: '536px',
  },
  width: '1340px',
  height: '536px',
};

SwiperMain.defaultProps = {
  itemList: [],
  itemProps: {},
  ...SwiperMainDefaultProps,
};

export const SwiperBanner = (props) => {
  const carouselProps = {
    delay: 8000,
    slidesPerView: 1,
    spaceBetween: 0,
    autoplay: true,
    loop: true,
  };
  return (
    <Swiper
      round
      control={'outer'}
      arrowProps={{ type: '15', position: 'outer' }}
      itemComponent={SwiperBannerItem}
      carouselProps={carouselProps}
      {...props}
    />
  );
};

SwiperBanner.displayName = 'SwiperBanner';

SwiperBanner.propTypes = {
  itemList: PropTypes.array,
  itemProps: PropTypes.object,
  arrowProps: PropTypes.object,
  onClick: PropTypes.func,
  carouselProps: PropTypes.object,
};

export const SwiperBannerDefaultProps = {
  carouselProps: {
    delay: 8000,
    slidesPerView: 1,
    spaceBetween: 0,
    autoplay: true,
    loop: true,
  },
  itemProps: {
    width: '1540px',
    height: '180px',
  },
  round: true,
  width: '1540px',
  height: '180px',
};

SwiperBanner.defaultProps = {
  itemList: [],
  itemProps: {},
  ...SwiperBannerDefaultProps,
};

export const SwiperEdgeBanner = (props) => (
  <Swiper
    control={'edge'}
    arrowProps={{ type: '10', position: 'inner', padding: '14px' }}
    itemComponent={SwiperBannerPopupItem}
    {...props}
  />
);

SwiperEdgeBanner.displayName = 'SwiperEdgeBanner';

SwiperEdgeBanner.propTypes = {
  itemList: PropTypes.array,
  itemProps: PropTypes.object,
  arrowProps: PropTypes.object,
  onClick: PropTypes.func,
  carouselProps: PropTypes.object,
};

export const SwiperEdgeBannerDefaultProps = {
  carouselProps: {
    delay: 8000,
    slidesPerView: 1,
    spaceBetween: 0,
    autoplay: true,
    loop: true,
  },
  itemProps: {
    width: '380px',
    height: '160px',
  },
  radius: '12px',
  width: '380px',
  height: '160px',
};

SwiperEdgeBanner.defaultProps = {
  itemList: [],
  ...SwiperEdgeBannerDefaultProps,
};

export const SwiperContents = (props) => (
  <Swiper arrowProps={{ type: '15', position: 'outer' }} itemComponent={SwiperBannerItem} {...props} />
);

SwiperContents.displayName = 'SwiperContents';

SwiperContents.propTypes = {
  itemList: PropTypes.array,
  itemProps: PropTypes.object,
  arrowProps: PropTypes.object,
  itemComponent: PropTypes.elementType,
  onClick: PropTypes.func,
  carouselProps: PropTypes.object,
};

export const SwiperContentsDefaultProps = {
  carouselProps: {
    speed: 500,
    slidesPerView: 6,
    spaceBetween: 20,
    autoplay: false,
    loop: false,
  },
  itemProps: {
    width: '240px',
    height: '240px',
  },
  width: '1540px',
  height: '240px',
};

SwiperContents.defaultProps = {
  itemList: [],
  ...SwiperContentsDefaultProps,
};

export const SwiperContentsEvent = (props) => (
  <Swiper arrowProps={{ type: '15', position: 'outer' }} itemComponent={SwiperBannerItemATag} {...props} />
);

SwiperContentsEvent.displayName = 'SwiperContentsEvent';

SwiperContentsEvent.propTypes = {
  itemList: PropTypes.array,
  itemProps: PropTypes.object,
  arrowProps: PropTypes.object,
  itemComponent: PropTypes.elementType,
  onClick: PropTypes.func,
  carouselProps: PropTypes.object,
};

export const SwiperContentsEventDefaultProps = {
  carouselProps: {
    speed: 500,
    slidesPerView: 6,
    spaceBetween: 20,
    autoplay: false,
    loop: false,
  },
  itemProps: {
    width: '240px',
    height: '240px',
  },
  width: '1540px',
  height: '240px',
};

SwiperContentsEvent.defaultProps = {
  itemList: [],
  ...SwiperContentsEventDefaultProps,
};

export const SwiperMainExhibition = (props) => (
  <Swiper
    control="outerLabel"
    arrowProps={{ type: '20', position: 'inner', padding: '280px', color: '#ffffff' }}
    itemComponent={SwiperMainExhibitionItem}
    {...props}
  />
);

SwiperMainExhibition.displayName = 'SwiperMainExhibition';

SwiperMainExhibition.propTypes = {
  itemList: PropTypes.array,
  itemProps: PropTypes.object,
  onClick: PropTypes.func,
  carouselProps: PropTypes.object,
};

export const SwiperMainExhibitionDefaultProps = {
  carouselProps: {
    delay: 3500,
    slidesPerView: 'auto',
    spaceBetween: 0,
    autoplay: false,
    navigation: true,
    loop: true,
    centeredSlides: true,
    effect: 'creative',
    creativeEffect: {
      prev: {
        shadow: true,
        translate: [-940, 0, -200],
      },
      next: {
        shadow: true,
        translate: [940, 0, -200],
      },
    },
  },
  itemProps: { width: '840px', height: '490px', radius: '12px' },
  width: '1540px',
  height: '490px',
};

SwiperMainExhibition.defaultProps = {
  itemList: [],
  ...SwiperMainExhibitionDefaultProps,
};

export const SwiperMdPick = React.forwardRef(({ ...props }, ref) => {
  return (
    <Swiper
      arrowProps={{ type: '15', position: 'inner', color: '#fff' }}
      itemComponent={MdPickCarouselItem}
      control="whiteInnerLabel"
      ref={ref}
      {...props}
    />
  );
});
SwiperMdPick.displayName = 'SwiperMdPick';

SwiperMdPick.propTypes = {
  itemList: PropTypes.array,
  itemProps: PropTypes.object,
  arrowProps: PropTypes.object,
  onClick: PropTypes.func,
  carouselProps: PropTypes.object,
  width: PropTypes.string,
};

export const SwiperMdPickDefaultProps = {
  carouselProps: {
    delay: 3500,
    slidesPerView: 1,
    spaceBetween: 0,
    autoplay: false,
    loop: true,
  },
  itemProps: {
    width: '460px',
    height: '460px',
  },
  round: true,
  width: '460px',
  height: '460px',
};

SwiperMdPick.defaultProps = {
  itemList: [],
  itemProps: {},
  ...SwiperMdPickDefaultProps,
};

export const SwiperMenu = React.forwardRef(({ ...props }, ref) => (
  <Swiper arrowProps={{ type: '10', position: 'outer' }} itemComponent={MenuCarouselItem} {...props} ref={ref} />
));

SwiperMenu.displayName = 'SwiperMenu';

SwiperMenu.propTypes = {
  itemList: PropTypes.array,
  itemProps: PropTypes.object,
  arrowProps: PropTypes.object,
  itemComponent: PropTypes.elementType,
  onClick: PropTypes.func,
  carouselProps: PropTypes.object,
};

export const SwiperMenuDefaultProps = {
  carouselProps: {
    speed: 500,
    slidesPerView: 'auto',
    spaceBetween: 0,
    autoplay: false,
    loop: false,
    freeMode: { enabled: true, sticky: true },
    slideToClickedSlide: true,
    normalizeSlideIndex: false, //left 만 active 되지 않도록
    allowTouchMove: false,
  },
  itemProps: {
    width: 'fit-content',
    height: '46px',
  },
  width: '980px',
  height: '46px',
};

SwiperMenu.defaultProps = {
  itemList: [],
  ...SwiperMenuDefaultProps,
};

export const SwiperAdBanner = (props) => (
  <Swiper
    hideUnusableArrow
    arrowProps={{ type: '8', position: 'outer', padding: '22px' }}
    itemComponent={SwiperAdBannerItem}
    {...props}
  />
);

SwiperAdBanner.displayName = 'SwiperAdBanner';

SwiperAdBanner.propTypes = {
  itemList: PropTypes.array,
  itemProps: PropTypes.object,
  arrowProps: PropTypes.object,
  onClick: PropTypes.func,
  carouselProps: PropTypes.object,
};

export const SwiperAdBannerDefaultProps = {
  carouselProps: {
    delay: 4000,
    slidesPerView: 1,
    spaceBetween: 20,
    autoplay: true,
    loop: true,
  },
  itemProps: {
    width: '169px',
    height: '50px',
  },
  round: false,
  width: '169px',
  height: '50px',
};

SwiperAdBanner.defaultProps = {
  itemList: [],
  itemProps: {},
  ...SwiperAdBannerDefaultProps,
};

export const SwiperAdBannerRef = React.forwardRef((props, ref) => {
  return (
    <Swiper
      ref={ref}
      hideUnusableArrow
      arrowProps={{ type: '8', position: 'outer', padding: '22px' }}
      itemComponent={SwiperAdBannerItem}
      {...props}
    />
  );
});
SwiperAdBannerRef.displayName = 'SwiperAdBannerRef';

SwiperAdBannerRef.propTypes = {
  itemList: PropTypes.array,
  itemProps: PropTypes.object,
  arrowProps: PropTypes.object,
  onClick: PropTypes.func,
  carouselProps: PropTypes.object,
};

export const SwiperAdBannerRefDefaultProps = {
  carouselProps: {
    delay: 4000,
    slidesPerView: 1,
    spaceBetween: 20,
    autoplay: true,
    loop: true,
  },
  itemProps: {
    width: '169px',
    height: '50px',
  },
  round: false,
  width: '169px',
  height: '50px',
};

SwiperAdBannerRef.defaultProps = {
  itemList: [],
  itemProps: {},
  ...SwiperAdBannerRefDefaultProps,
};